function createObject() {
    let example = {
        id: 'PfadeTabea',
        workOrder: `
            Wählen Sie aus den vier Diagnoseaufgaben zunächst genau zwei aus. Betrachen Sie dann die Lösungen und entscheiden Sie sich für genau eine - möglichst passgenaue - Förderaufgabe.
            `,
        image: require("@/assets/swv/Pfade_Tabea/Pfade_Tabea_1.png"),
        imageSizePercent: 30,
        mediaDescription: 'Setzen Sie sich so mit Tabeas Lösung zur folgenden Aufgabe auseinander, dass Sie in einem nächsten Schritt weitere passende Diagnoseaufgaben auswählen können.',
        pupilName: 'Tabea',
        elements: [
            [
                // das ist der diagnose bereich, hier wählt man 2 von 4 aus
                {
                    selectCount: 2,
                    typeText: "Diagnoseaufgabe",
                    question: "Wählen Sie nun <u>genau zwei</u> weitere Diagnoseaufgaben aus, zu denen Sie die Lösung sehen möchten, um die Diagnose zu vertiefen.",
                    diagnostics: [{
                            id: 1,
                            // taskText: 'Diagnoseaufgabe 1',
                            taskImage: require("@/assets/swv/Pfade_Tabea/Pfade_Tabea_2.png"),
                            // solutionText: 'Schüler:innen Lösung Diagnoseaufgabe 1',
                            solutionImage: require("@/assets/swv/Pfade_Tabea/Pfade_Tabea_3.png"),
                        },
                        {
                            id: 2,
                            // taskText: 'Diagnoseaufgabe 2',
                            taskImage: require("@/assets/swv/Pfade_Tabea/Pfade_Tabea_4.png"),
                            // solutionText: 'Schüler:innen Lösung Diagnoseaufgabe 2',
                            solutionImage: require("@/assets/swv/Pfade_Tabea/Pfade_Tabea_5.png"),
                        },
                        {
                            id: 3,
                            // taskText: 'Diagnoseaufgabe 3',
                            taskImage: require("@/assets/swv/Pfade_Tabea/Pfade_Tabea_6.png"),
                            // solutionText: 'Schüler:innen Lösung Diagnoseaufgabe 3',
                            solutionImage: require("@/assets/swv/Pfade_Tabea/Pfade_Tabea_7.png"),
                        },
                        {
                            id: 4,
                            // taskText: 'Diagnoseaufgabe 4',
                            taskImage: require("@/assets/swv/Pfade_Tabea/Pfade_Tabea_8.png"),
                            // solutionText: 'Schüler:innen Lösung Diagnoseaufgabe 4',
                            solutionImage: require("@/assets/swv/Pfade_Tabea/Pfade_Tabea_9.png"),
                        }
                    ]
                }
            ],
            [
                // this is stage 2 (föderaufgabe)
                {
                    selectCount: 1,
                    typeText: "Förderaufgabe",
                    question: "Wählen Sie nun <u>eine</u> passende Förderaufgabe aus, die zu Ihren gewählten Diagnoseaufgaben passt.",
                    diagnostics: [{
                            id: 1,
                            // taskText: 'Förderaufgabe 1',
                            taskImage: require("@/assets/swv/Pfade_Tabea/Pfade_Tabea_10.png"),
                            // solutionText: 'Schüler:innen Lösung Förderaufgabe 1',
                            // solutionImage: require("@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Marie.png"),
                        },
                        {
                            id: 2,
                            // taskText: 'Förderaufgabe 2',
                            taskImage: require("@/assets/swv/Pfade_Tabea/Pfade_Tabea_11.png"),
                            // solutionText: 'Schüler:innen Lösung Förderaufgabe 2',
                            // solutionImage: require("@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Marie.png"),
                        },
                        {
                            id: 3,
                            // taskText: 'Förderaufgabe 3',
                            taskImage: require("@/assets/swv/Pfade_Tabea/Pfade_Tabea_12.png"),
                            // solutionText: 'Schüler:innen Lösung Förderaufgabe 3',
                            // solutionImage: require("@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Marie.png"),
                        },
                        {
                            id: 4,
                            // taskText: 'Förderaufgabe 4',
                            taskImage: require("@/assets/swv/Pfade_Tabea/Pfade_Tabea_13.png"),
                            // solutionText: 'Schüler:innen Lösung Förderaufgabe 4',
                            // solutionImage: require("@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Marie.png"),
                        }
                    ]
                },
            ],
        ]
    }

    const DA_1 = `
        Diagnoseaufgabe 1 zeigt, dass Tabea in diesem Beispiel, in dem nicht zusätzlich gebündelt werden muss, leeren Stellen in der Stellenwerttafel die Menge 0 im Zahlzeichen zuordnen kann.
        <br />
        `
    const DA_2 = `
        Diagnoseaufgabe 2 lässt erkennen, dass Tabea die jeweiligen Anzahlen des dargestellten Materials direkt in das Zahlzeichen übersetzt.
        Die 16 Zehnerstangen bündelt sie nicht in einen Hunderter und sechs Zehner, sondern überträgt die Anzahl 16 ungebündelt als zwei Ziffern an die Zehnerposition des Zahlzeichens.
        Dadurch verschieben sich die nächstgrößeren Stellenwerte: Hunderter werden zu Tausendern, Tausender werden zu Zehntausendern.
        <br />
        `

    const DA_3 = `
        Diagnoseaufgabe 3 zeigt, dass Tabea die jeweiligen Anzahlen des Würfelmaterials pro Stellenwert legt.
        Sie bündelt das Material nicht weiter, sodass mehr als zehn Objekte derselben Bündelungseinheit vorliegen.
        Bei der Übersetzung in das Zahlzeichen notiert sie die Anzahlen je Stellenwert ungebündelt hintereinander, sodass sie sowohl die Einer - als auch die Zehnerstelle mit zwei Ziffern besetzt.
        Dadurch verschiebt sich die Stellenwerte um zwei Stellen.
        <br /> 
        `
    const DA_4 = `
        Diagnoseaufgabe 4 lässt erkennen, dass solange keine Bündelung erforderlich ist, es Tabea gelingt, die vorliegenden Additionsaufgaben durch stellenweises Rechnen zu lösen.
        Ist eine Bündelung erforderlich, notiert sie zwei Ziffern zur Besetzung eines Stellenwerts.
        Sie führt keine Bündelung durch bzw. fasst Elemente der gleichen Bündelungseinheit nicht zusammen.
        <br />
        `

    const FA_1 = `
        Förderaufgabe 1 bietet Tabea die Möglichkeit den Lösungsweg eines anderen Kindes nachzuvollziehen.
        Durch die Handlung am Material und die Aufforderung zu erklären, wie Marie auf die Zahl 130 und deren Notation in der Stellenwerttafel kommt, wird sie dazu angeregt, über das Bündeln von zehn der insgesamt 13 Zehner zu einem Hunderter nachzudenken.
        Außerdem ist die Einerposition unbesetzt, es muss also zusätzlich über die Null an der Einerstelle nachgedacht werden.
        Somit kann diese Aufgabe zur passgenauen Förderung von Tabeas diagnostizierten Schwierigkeiten beim Bündeln beitragen.
        `

    const FA_2_Star = `
        Förderaufgabe 2 wirft das Problem auf, dass eine Zehnerstange zunächst entbündelt werden muss, bevor sechs Einer abgezogen werden können.
        Dies fördert zwar den Entbündelungsgedanken, dieser wird allerdings in den Diagnoseaufgaben nicht angesprochen.
        `
    const FA_2 = `
        Förderaufgabe 2 wirft das Problem auf, dass eine Zehnerstange zunächst entbündelt werden muss, bevor sechs Einer weggenommen werden können.
        Dies fördert zwar den Entbündelungsgedanken, dieser wird allerdings in den Diagnoseaufgaben nicht angesprochen.
        Es könnte also passgenauere Förderaufgaben zu den ausgewählten Diagnoseaufgaben und deren Lösungen geben.
        Schauen Sie sich das noch einmal an.
        `

    const FA_3 = `
        Förderaufgabe 3 stellt Tabea vor die Herausforderung, darüber nachzudenken, wie sie unter Verwendung von möglichst wenig Material die dargestellte Zahl schnell erkennen kann.
        Dafür ist es erforderlich, dass Tabea Material eintauscht, also fortgesetzt bündelt.
        Somit kann diese Aufgabe zur passgenauen Förderung von Tabeas diagnostizierten Schwierigkeiten beim Bündeln beitragen.
        `

    const FA_4 = `
        Förderaufgabe 4 fordert Tabea auf verschiedene Aufgaben zu berechnen, bei denen teilweise gebündelt werden muss und teilweise nicht.
        Da aus der Diagnose hervorgeht, dass es Tabea noch Schwierigkeiten bereitet Bündelungen vorzunehmen und diese Aufgabe wenig Gesprächsanlässe bietet, würde es passgenauere Förderaufgaben geben.
        Schauen Sie sich das noch einmal an.
        `
    const PASSUNG_SEHR_GUT = `
        Diese Förderaufgabe passt sehr gut zur Diagnose.
        <br />
        `
    const PASSUNG_GUT = `
        Diese Förderaufgabe passt ganz gut zur Diagnose.
        <br />
        `
    const PASSUNG_SCHLECHT = `
        Diese Förderaufgabe hätten wir nach der Diagnose eher nicht gewählt.
        <br />
        `

    const PASSUNG_SEHR_SCHLECHT = `
        Diese Aufgabe hätten wir nicht gewählt.
        <br />
        `

    example.responses = [{
            id: '112',
            text: PASSUNG_SEHR_GUT + DA_1 + DA_2 + FA_1
        },
        {
            id: '113',
            text: PASSUNG_SEHR_GUT + DA_1 + DA_3 + FA_1
        },
        {
            id: '114',
            text: PASSUNG_GUT + DA_1 + DA_4 + FA_1
        },
        {
            id: '123',
            text: PASSUNG_SEHR_GUT + DA_2 + DA_3 + FA_1
        },
        {
            id: '124',
            text: PASSUNG_SEHR_GUT + DA_2 + DA_4 + FA_1
        },
        {
            id: '134',
            text: PASSUNG_GUT + DA_3 + DA_4 + FA_1
        },

        {
            id: '212',
            text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_2 + FA_2
        },
        {
            id: '213',
            text: PASSUNG_SCHLECHT + DA_1 + DA_3 + FA_2
        },
        {
            id: '214',
            text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_4 + FA_2
        },
        {
            id: '223',
            text: PASSUNG_GUT + DA_2 + DA_3 + FA_2_Star + " Dennoch könnte diese Aufgabe Tabea dazu anregen sich auch über Bündelungsaktivitäten Gedanken zu machen. Es wäre allerdings in jedem Fall eine Aufgabe zum fortgesetzten Bündeln in der Förderung anzuschließen."
        },
        {
            id: '224',
            text: PASSUNG_SEHR_SCHLECHT + DA_2 + DA_4 + FA_2
        },
        {
            id: '234',
            text: PASSUNG_SCHLECHT + DA_3 + DA_4 + FA_2
        },

        {
            id: '312',
            text: PASSUNG_GUT + DA_1 + DA_2 + FA_3
        },
        {
            id: '313',
            text: PASSUNG_GUT + DA_1 + DA_3 + FA_3
        },
        {
            id: '314',
            text: PASSUNG_GUT + DA_1 + DA_4 + FA_3
        },
        {
            id: '323',
            text: PASSUNG_SEHR_GUT + DA_2 + DA_3 + FA_3
        },
        {
            id: '324',
            text: PASSUNG_GUT + DA_2 + DA_4 + FA_3
        },
        {
            id: '334',
            text: PASSUNG_GUT + DA_3 + DA_4 + FA_3
        },

        {
            id: '412',
            text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_2 + FA_4
        },
        {
            id: '413',
            text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_3 + FA_4
        },
        {
            id: '414',
            text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_4 + FA_4 + " Hier wäre eher eine Reproduktion ihres vorherigen Fehlers zu erwarten."
        },
        {
            id: '423',
            text: PASSUNG_SEHR_SCHLECHT + DA_2 + DA_3 + FA_4
        },
        {
            id: '424',
            text: PASSUNG_SEHR_SCHLECHT + DA_2 + DA_4 + FA_4 + " Hier wäre eher eine Reproduktion ihres vorherigen Fehlers zu erwarten."
        },
        {
            id: '434',
            text: PASSUNG_SEHR_SCHLECHT + DA_3 + DA_4 + FA_4 + " Hier wäre eher eine Reproduktion ihres vorherigen Fehlers zu erwarten."
        },

    ]

    return example;
}

export default createObject();