<template>
  <div>
    <div class="greyBackground"></div>
    <v-card class="greyColor" elevation="0">
      <h2 class="faledia-caption">Diagnose & Förderung</h2>

      <p>
        Auch wenn das Hintergrundwissen essentiell ist, ist es nicht weniger
        wichtig zu trainieren, dieses Wissen auf konkrete Dokumente von Kindern
        anwenden zu können. Vor allem ist es dabei zentral erfassen zu können,
        in welchen Bereichen das Stellenwertverständnis bereits gut ausgebildet
        ist und wo eventuell Probleme vorliegen.
      </p>

      <p>
        Im Hintergrundteil haben Sie erfahren, dass ein sicherer Wechsel
        zwischen dem notierten Zahlzeichen, dem dazugehörigen Zahlwort und der
        entsprechenden Menge einer Zahl, also die Fähigkeit zum
        Darstellungswechsel, ein Indikator für ein tragfähiges
        Stellenwertverständnis ist. Ausschlaggebend ist jedoch nicht nur diese
        Fähigkeit alleine, sondern auch ein Metawissen über die Durchführung der
        Übersetzungstätigkeiten. Das bedeutet, dass die Person weiß und erklären
        kann, wie die einzelnen Ziffern des Zahlzeichens, die Bestandteile des
        Zahlwortes und die Anzahlen der einzelnen Bündelungseinheiten
        zusammenhängen (vgl. Schulz 2014, S. 150).
      </p>

      <p>
        Bei den folgenden Aufgaben wird ein Darstellungswechsel von einer
        bildlichen Darstellung in die Stellenwerttafel und in die
        Zahlschreibweise verlangt. Betrachten Sie die Kinderdokumente und
        gruppieren Sie diese nach ähnlichen Vorgehensweisen oder Fehlern.
      </p>
      <div style="background-color: white">
        <LernbausteinGruppen
          :id="LBSTGruppen[0].id"
          :checkAfterHowManyItems="LBSTGruppen[0].checkAfterHowManyItems"
          :checkAfterExactItemCount="LBSTGruppen[0].checkAfterExactItemCount"
          :showGroupNames="LBSTGruppen[0].showGroupNames"
          :workOrder="LBSTGruppen[0].workOrder"
          :possibleCategories="LBSTGruppen[0].possibleCategories"
          :elements="LBSTGruppen[0].elements"
          :responses="LBSTGruppen[0].responses"
        >
          <template v-slot:alternative-content>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td class="text-center">
                      <strong>Probleme mit der Null</strong>
                      <vuetify-audio
                        ref="audioPlayer5"
                        :file="
                          require('@/assets/swvi/Gruppendiagnose/SWV-I_Gruppendiagnose_1_Probleme_mit_der_0.mp3')
                        "
                        color="primary"
                        flat
                        :ended="
                          () =>
                            audioEnded(
                              'Gruppendiagnose/SWV-I_Gruppendiagnose_1_Probleme_mit_der_0.mp3'
                            )
                        "
                        @click.native="
                          () =>
                            audioPlayerClicked(
                              $event,
                              'Gruppendiagnose/SWV-I_Gruppendiagnose_1_Probleme_mit_der_0.mp3',
                              $refs.audioPlayer5
                            )
                        "
                        class="audioPlayerBigger"
                      ></vuetify-audio>
                    </td>
                    <td>
                      <div class="imageTableImages biggerTableImageContainer">
                        <AppExpandableImage
                          :src="
                            require('@/assets/swve/Gruppendiagnose/Fehlertyp1_Dok1.jpg')
                          "
                          contain
                          parentId="SWVI"
                          elementId="Gruppendiagnose/Fehlertyp1_Dok1.jpg"
                        />
                      </div>
                      <div class="imageTableImages biggerTableImageContainer">
                        <AppExpandableImage
                          :src="
                            require('@/assets/swve/Gruppendiagnose/Fehlertyp1_Dok2.jpg')
                          "
                          contain
                          parentId="SWVI"
                          elementId="Gruppendiagnose/Fehlertyp1_Dok2.jpg"
                        />
                      </div>
                      <div class="imageTableImages biggerTableImageContainer">
                        <AppExpandableImage
                          :src="
                            require('@/assets/swve/Gruppendiagnose/Fehlertyp1_Dok3.jpg')
                          "
                          contain
                          parentId="SWVI"
                          elementId="Gruppendiagnose/Fehlertyp1_Dok3.jpg"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <strong>Fehlende Bündelung</strong>
                      <vuetify-audio
                        ref="audioPlayer6"
                        :file="
                          require('@/assets/swvi/Gruppendiagnose/SWV-I_Gruppendiagnose_2_Fehlende_Bündelung.mp3')
                        "
                        color="primary"
                        flat
                        :ended="
                          () =>
                            audioEnded(
                              'Gruppendiagnose/SWV-I_Gruppendiagnose_2_Fehlende_Bündelung.mp3'
                            )
                        "
                        @click.native="
                          () =>
                            audioPlayerClicked(
                              $event,
                              'Gruppendiagnose/SWV-I_Gruppendiagnose_2_Fehlende_Bündelung.mp3',
                              $refs.audioPlayer6
                            )
                        "
                        class="audioPlayerBigger"
                      ></vuetify-audio>
                    </td>
                    <td>
                      <div class="imageTableImages biggerTableImageContainer">
                        <AppExpandableImage
                          :src="
                            require('@/assets/swve/Gruppendiagnose/Fehlertyp2_Dok1.jpg')
                          "
                          contain
                          parentId="SWVI"
                          elementId="Gruppendiagnose/Fehlertyp2_Dok1.jpg"
                        />
                      </div>
                      <div class="imageTableImages biggerTableImageContainer">
                        <AppExpandableImage
                          :src="
                            require('@/assets/swve/Gruppendiagnose/Fehlertyp2_Dok2.jpg')
                          "
                          contain
                          parentId="SWVI"
                          elementId="Gruppendiagnose/Fehlertyp2_Dok2.jpg"
                        />
                      </div>
                      <div class="imageTableImages biggerTableImageContainer">
                        <AppExpandableImage
                          :src="
                            require('@/assets/swve/Gruppendiagnose/Fehlertyp2_Dok3.jpg')
                          "
                          contain
                          parentId="SWVI"
                          elementId="Gruppendiagnose/Fehlertyp2_Dok3.jpg"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <strong>Falsche Bündelungseinheit</strong>
                      <vuetify-audio
                        ref="audioPlayer7"
                        :file="
                          require('@/assets/swvi/Gruppendiagnose/SWV-I_Gruppendiagnose_3_Falsche_Bündelungseinheit.mp3')
                        "
                        color="primary"
                        flat
                        :ended="
                          () =>
                            audioEnded(
                              'Gruppendiagnose/SWV-I_Gruppendiagnose_3_Falsche_Bündelungseinheit.mp3'
                            )
                        "
                        @click.native="
                          () =>
                            audioPlayerClicked(
                              $event,
                              'Gruppendiagnose/SWV-I_Gruppendiagnose_3_Falsche_Bündelungseinheit.mp3',
                              $refs.audioPlayer7
                            )
                        "
                        class="audioPlayerBigger"
                      ></vuetify-audio>
                    </td>
                    <td>
                      <div class="imageTableImages biggerTableImageContainer">
                        <AppExpandableImage
                          :src="
                            require('@/assets/swve/Gruppendiagnose/Fehlertyp3_Dok1.jpg')
                          "
                          contain
                          parentId="SWVI"
                          elementId="Gruppendiagnose/Fehlertyp3_Dok1.jpg"
                        />
                      </div>
                      <div class="imageTableImages biggerTableImageContainer">
                        <AppExpandableImage
                          :src="
                            require('@/assets/swve/Gruppendiagnose/Fehlertyp3_Dok2.jpg')
                          "
                          contain
                          parentId="SWVI"
                          elementId="Gruppendiagnose/Fehlertyp3_Dok2.jpg"
                        />
                      </div>
                      <div class="imageTableImages biggerTableImageContainer">
                        <AppExpandableImage
                          :src="
                            require('@/assets/swve/Gruppendiagnose/Fehlertyp3_Dok3.jpg')
                          "
                          contain
                          parentId="SWVI"
                          elementId="Gruppendiagnose/Fehlertyp3_Dok3.jpg"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <strong>Falsche Reihenfolge der Stellenwerte</strong>
                      <vuetify-audio
                        ref="audioPlayer8"
                        :file="
                          require('@/assets/swvi/Gruppendiagnose/SWV-I_Gruppendiagnose_4_Falsche_Reihenfolge_der_Stellenwerte.mp3')
                        "
                        color="primary"
                        flat
                        :ended="
                          () =>
                            audioEnded(
                              'Gruppendiagnose/SWV-I_Gruppendiagnose_4_Falsche_Reihenfolge_der_Stellenwerte.mp3'
                            )
                        "
                        @click.native="
                          () =>
                            audioPlayerClicked(
                              $event,
                              'Gruppendiagnose/SWV-I_Gruppendiagnose_4_Falsche_Reihenfolge_der_Stellenwerte.mp3',
                              $refs.audioPlayer8
                            )
                        "
                        class="audioPlayerBigger"
                      ></vuetify-audio>
                    </td>
                    <td>
                      <div class="imageTableImages biggerTableImageContainer">
                        <AppExpandableImage
                          :src="
                            require('@/assets/swve/Gruppendiagnose/Fehlertyp4_Dok1.jpg')
                          "
                          contain
                          parentId="SWVI"
                          elementId="Gruppendiagnose/Fehlertyp4_Dok1.jpg"
                        />
                      </div>
                      <div class="imageTableImages biggerTableImageContainer">
                        <AppExpandableImage
                          :src="
                            require('@/assets/swve/Gruppendiagnose/Fehlertyp4_Dok2.jpg')
                          "
                          contain
                          parentId="SWVI"
                          elementId="Gruppendiagnose/Fehlertyp4_Dok2.jpg"
                        />
                      </div>
                      <div class="imageTableImages biggerTableImageContainer">
                        <AppExpandableImage
                          :src="
                            require('@/assets/swve/Gruppendiagnose/Fehlertyp4_Dok3.jpg')
                          "
                          contain
                          parentId="SWVI"
                          elementId="Gruppendiagnose/Fehlertyp4_Dok3.jpg"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </LernbausteinGruppen>
      </div>

      <p>
        Insbesondere beim Darstellungswechsel wird oft sichtbar, inwiefern die
        Prinzipien des Bündelns sowie des Stellenwerts und des Zahlwerts
        verstanden und entsprechend berücksichtigt werden. Das gilt besonders
        dann, wenn die Darstellungen der Zahlen nicht den Konventionen
        entsprechen, wie z. B. eine bildliche Darstellung von 13 ungebündelten
        Zehnern. Um zu diagnostizieren, inwiefern Prinzipien unseres
        Stellenwertsystems verstanden und berücksichtigt wurden, sind Aufgaben
        geeignet, in denen ein Darstellungswechsel erforderlich ist und kleine
        Irritationen eingebaut sind. Es ist jedoch anzumerken, dass nicht jede
        Aufgabe, in der ein Darstellungswechsel gefordert ist, zur Diagnose
        jedes der für das Stellenwertverständnis relevanten Prinzipien geeignet
        ist. Mit einer Aufgabe, in der keine Bündelung erforderlich ist, werden
        Sie auch nichts darüber erfahren, inwiefern ein Kind das
        Bündelungsprinzip bereits berücksichtigt und somit vermutlich auch
        verstanden hat. Insofern ist die Wahl der Aufgaben für eine Diagnose im
        Hinblick darauf gut abzuwägen, was man erfahren möchte und was die
        gestellten Aufgaben überhaupt für Erkenntnisse liefern können.
      </p>

      <p>
        Bisher lag der Fokus auf der Identifikation typischer Fehler. Im Sinne
        einer förderorientierten Diagnose ist es jedoch unabdingbar, die Ursache
        solcher Fehler näher zu bestimmen. Nur wenn man die Ursache eines
        Fehlers kennt, kann man passende Fördermaßnahmen ergreifen, um die
        Fehlvorstellungen der Kinder aufzuarbeiten.
      </p>
      <div style="background-color: white">
        <LernbausteinStempeln
          :id="LBSTStempeln[0].id"
          :workOrder="LBSTStempeln[0].workOrder"
          :image="LBSTStempeln[0].image"
          :audio="LBSTStempeln[0].audio"
          :elements="LBSTStempeln[0].elements"
        >
          <template v-slot:alternative-content-0>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>
                      <div class="imageTableImages tableImageContainer">
                        <AppExpandableImage
                          :src="
                            require('@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Marie.png')
                          "
                          contain
                          parentId="SWVI"
                          elementId="Einzeldiagnose/Einzeldiagnose_Rechnung_Marie.png"
                        />
                      </div>
                    </td>
                    <td>
                      <p>
                        In der Abbildung siehst Du, wie Marie drei Aufgabe
                        gelöst hat. Anhand der vierten Aufgabe 58 + 21 wird
                        rekonstruiert, wie Marie beim Lösen der Aufgaben
                        vermutlich vorgegangen ist, über welche Kompetenzen sie
                        bereits verfügt und was wir über die Ursache des Fehlers
                        vermuten können.
                      </p>
                      <p>
                        Bei der Aufgabe 58 + 21 würde Marie vermutlich zu dem
                        Ergebnis 61 kommen. Dies lässt sich aus den Lösungen der
                        vorherigen Aufgaben schließen, bei denen sie die beiden
                        Ziffern des zweiten Summanden schrittweise zum Einer des
                        ersten Summanden addiert. Mit dieser Vorgehensweise
                        würde sie bei der Aufgabe 58 + 21 wie folgt rechnen: 58
                        + 2 + 1, womit sie auf die Summe 61 käme.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>

          <template v-slot:alternative-content-1>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>
                      <div class="imageTableImages tableImageContainer">
                        <AppExpandableImage
                          :src="
                            require('@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Marie.png')
                          "
                          contain
                          parentId="SWVI"
                          elementId="Einzeldiagnose/Einzeldiagnose_Rechnung_Marie.png"
                        />
                      </div>
                    </td>
                    <td>
                      <p>
                        Marie kann bereits einstellige Zahlen korrekt zu
                        zweistelligen Zahlen addieren. Beim Addieren
                        einstelliger Zahlen zu zweistelligen Zahlen kommt sie
                        prinzipiell zum richtigen Ergebnis, wobei ihr auch der
                        Zehnerübergang keine Schwierigkeiten bereitet. Das
                        erkennt man zum Beispiel an der dritten Lösung: Marie
                        rechnet anstatt 18 + 72 die Aufgabe 18 + 7 + 2 = 27 und
                        gelangt für ihre gedachte Aufgabe auf die richtige
                        Summe.
                      </p>
                      <p>
                        Die Ursache für Maries Fehler könnte sein, dass Marie
                        das Prinzip des Stellenwerts noch nicht verstanden hat.
                        Das lässt sich anhand dessen vermuten, dass sie die
                        Stellenwerte des zweiten Summanden außer Acht lässt,
                        während sie dessen Ziffern zum ersten Summanden addiert.
                        Bei der Berechnung der Summe behandelt sie beide Ziffern
                        des zweiten Summanden als Einer.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>

          <template v-slot:alternative-content>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>
                      <div class="imageTableImages tableImageContainer">
                        <AppExpandableImage
                          :src="
                            require('@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Marie.png')
                          "
                          contain
                          parentId="SWVI"
                          elementId="Einzeldiagnose/Einzeldiagnose_Rechnung_Marie.png"
                        />
                      </div>
                    </td>
                    <td>
                      <p>
                        In der Abbildung siehst Du, wie Marie drei Aufgabe
                        gelöst hat. Anhand der vierten Aufgabe 58 + 21 wird
                        rekonstruiert, wie Marie beim Lösen der Aufgaben
                        vermutlich vorgegangen ist, über welche Kompetenzen sie
                        bereits verfügt und was wir über die Ursache des Fehlers
                        vermuten können.
                      </p>
                      <p>
                        Bei der Aufgabe 58 + 21 würde Marie vermutlich zu dem
                        Ergebnis 61 kommen. Dies lässt sich aus den Lösungen der
                        vorherigen Aufgaben schließen, bei denen sie die beiden
                        Ziffern des zweiten Summanden schrittweise zum Einer des
                        ersten Summanden addiert. Mit dieser Vorgehensweise
                        würde sie bei der Aufgabe 58 + 21 wie folgt rechnen: 58
                        + 2 + 1, womit sie auf die Summe 61 käme.
                      </p>
                      <p>
                        Marie kann bereits einstellige Zahlen korrekt zu
                        zweistelligen Zahlen addieren. Beim Addieren
                        einstelliger Zahlen zu zweistelligen Zahlen kommt sie
                        prinzipiell zum richtigen Ergebnis, wobei ihr auch der
                        Zehnerübergang keine Schwierigkeiten bereitet. Das
                        erkennt man zum Beispiel an der dritten Lösung: Marie
                        rechnet anstatt 18 + 72 die Aufgabe 18 + 7 + 2 = 27 und
                        gelangt für ihre gedachte Aufgabe auf die richtige
                        Summe.
                      </p>
                      <p>
                        Die Ursache für Maries Fehler könnte sein, dass Marie
                        das Prinzip des Stellenwerts noch nicht verstanden hat.
                        Das lässt sich anhand dessen vermuten, dass sie die
                        Stellenwerte des zweiten Summanden außer Acht lässt,
                        während sie dessen Ziffern zum ersten Summanden addiert.
                        Bei der Berechnung der Summe behandelt sie beide Ziffern
                        des zweiten Summanden als Einer.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </LernbausteinStempeln>
      </div>

      <AppButtonCollapse>
        <div style="background-color: white">
          <LernbausteinStempeln
            :id="LBSTStempeln[1].id"
            :workOrder="LBSTStempeln[1].workOrder"
            :image="LBSTStempeln[1].image"
            :audio="LBSTStempeln[1].audio"
            :elements="LBSTStempeln[1].elements"
          >
            <template v-slot:alternative-content-0>
              <v-simple-table class="imageTable">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>
                        <div class="imageTableImages tableImageContainer">
                          <AppExpandableImage
                            :src="
                              require('@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Taifun.png')
                            "
                            contain
                            parentId="SWVI"
                            elementId="Einzeldiagnose/Einzeldiagnose_Rechnung_Taifun.png"
                          />
                        </div>
                      </td>
                      <td>
                        <p>
                          In der Abbildung siehst Du, wie Taifun drei Aufgabe
                          gelöst hat. Anhand der vierten Aufgabe wird
                          rekonstruiert, wie Taifun beim Lösen der Aufgaben
                          vermutlich vorgegangen ist, über welche Kompetenzen er
                          bereits verfügt und was wir über die Ursache des
                          Fehlers vermuten können.
                        </p>
                        <p>
                          Würde Taifun wie in den vorherigen Lösungen vorgehen,
                          würde er vermutlich die Anzahl der dargestellten
                          Einer, nämlich 58, in der Einerspalte der
                          Stellenwerttafel notieren. Bei der Übersetzung in die
                          symbolische Zahl, würde er alle Ziffern ungebündelt
                          aus der Stellenwerttafel abschreiben und somit auf die
                          falsche Zahl 20358 kommen.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>

            <template v-slot:alternative-content-1>
              <v-simple-table class="imageTable">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>
                        <div class="imageTableImages tableImageContainer">
                          <AppExpandableImage
                            :src="
                              require('@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Taifun.png')
                            "
                            contain
                            parentId="SWVI"
                            elementId="Einzeldiagnose/Einzeldiagnose_Rechnung_Taifun.png"
                          />
                        </div>
                      </td>
                      <td>
                        <p>
                          Taifun kann bereits die Anzahl der Tausender,
                          Hunderter, Zehner und Einer aus der bildlichen
                          Darstellung korrekt in eine formale Darstellung in die
                          Stellentafel übertragen. Das er an dieser Stelle noch
                          keine Bündelung vornimmt, ist kein Fehler, da z. B. 46
                          in die Einerspalte einzutragen genauso richtig ist wie
                          4 in die Zehner- und 6 in die Einerspalte. Außerdem
                          trägt er die Stellen, die keine Elemente in der
                          bildlichen Darstellung zeigen, richtig mit einer Null
                          in die Stellenwerttafel ein, die er auch in die
                          symbolische Zahlschreibweise überträgt.
                        </p>
                        <p>
                          Die Ursache für seinen Fehler könnte sein, dass er das
                          Prinzip des Bündelns und das Prinzip des Stellenwerts
                          noch nicht verstanden hat. Das wird daran deutlich,
                          dass Taifun bei Anzahlen von mehr als 9 Elementen in
                          einem Stellenwert, diese zweistellige Anzahl der
                          Elemente in die Ziffernschreibweise der Zahl
                          überträgt, anstatt zu bündeln. So rückt jede
                          nachfolgende Ziffer in den nächsthöheren Stellenwert.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>

            <template v-slot:alternative-content>
              <v-simple-table class="imageTable">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>
                        <div class="imageTableImages tableImageContainer">
                          <AppExpandableImage
                            :src="
                              require('@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Taifun.png')
                            "
                            contain
                            parentId="SWVI"
                            elementId="Einzeldiagnose/Einzeldiagnose_Rechnung_Taifun.png"
                          />
                        </div>
                      </td>
                      <td>
                        <p>
                          In der Abbildung siehst Du, wie Taifun drei Aufgabe
                          gelöst hat. Anhand der vierten Aufgabe wird
                          rekonstruiert, wie Taifun beim Lösen der Aufgaben
                          vermutlich vorgegangen ist, über welche Kompetenzen er
                          bereits verfügt und was wir über die Ursache des
                          Fehlers vermuten können.
                        </p>
                        <p>
                          Würde Taifun wie in den vorherigen Lösungen vorgehen,
                          würde er vermutlich die Anzahl der dargestellten
                          Einer, nämlich 58, in der Einerspalte der
                          Stellenwerttafel notieren. Bei der Übersetzung in die
                          symbolische Zahl, würde er alle Ziffern ungebündelt
                          aus der Stellenwerttafel abschreiben und somit auf die
                          falsche Zahl 20358 kommen.
                        </p>
                        <p>
                          Taifun kann bereits die Anzahl der Tausender,
                          Hunderter, Zehner und Einer aus der bildlichen
                          Darstellung korrekt in eine formale Darstellung in die
                          Stellentafel übertragen. Das er an dieser Stelle noch
                          keine Bündelung vornimmt, ist kein Fehler, da z. B. 46
                          in die Einerspalte einzutragen genauso richtig ist wie
                          4 in die Zehner- und 6 in die Einerspalte. Außerdem
                          trägt er die Stellen, die keine Elemente in der
                          bildlichen Darstellung zeigen, richtig mit einer Null
                          in die Stellenwerttafel ein, die er auch in die
                          symbolische Zahlschreibweise überträgt.
                        </p>
                        <p>
                          Die Ursache für seinen Fehler könnte sein, dass er das
                          Prinzip des Bündelns und das Prinzip des Stellenwerts
                          noch nicht verstanden hat. Das wird daran deutlich,
                          dass Taifun bei Anzahlen von mehr als 9 Elementen in
                          einem Stellenwert, diese zweistellige Anzahl der
                          Elemente in die Ziffernschreibweise der Zahl
                          überträgt, anstatt zu bündeln. So rückt jede
                          nachfolgende Ziffer in den nächsthöheren Stellenwert.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </LernbausteinStempeln>
        </div>

        <AppButtonCollapse>
          <div style="background-color: white">
            <LernbausteinStempeln
              :id="LBSTStempeln[2].id"
              :workOrder="LBSTStempeln[2].workOrder"
              :image="LBSTStempeln[2].image"
              :audio="LBSTStempeln[2].audio"
              :elements="LBSTStempeln[2].elements"
            >
              <template v-slot:alternative-content-0>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>
                          <div class="imageTableImages tableImageContainer">
                            <AppExpandableImage
                              :src="
                                require('@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Clara.png')
                              "
                              contain
                              parentId="SWVI"
                              elementId="Einzeldiagnose/Einzeldiagnose_Rechnung_Clara.png"
                            />
                          </div>
                        </td>
                        <td>
                          <p>
                            In dieser Abbildung siehst Du, wie Clara drei
                            Aufgabe gelöst hat. Anhand der vierten Aufgabe 29 +
                            6 wird rekonstruiert, wie Clara beim Lösen der
                            Aufgaben vermutlich vorgegangen ist, über welche
                            Kompetenzen sie bereits verfügt und was wir über die
                            Ursache des Fehlers vermuten können.
                          </p>
                          <p>
                            Betrachtet man die Lösungen der oberen drei
                            Aufgaben, so fällt auf, dass Clara vermutlich
                            jeweils die beiden Summanden stellenweise, dabei
                            eventuell auch die Zehner und Einer ziffernweise,
                            betrachtet und separat addiert. Die dabei
                            herauskommenden Summen notiert sie hintereinander,
                            anstatt sie wiederum stellengerecht miteinander zu
                            addieren. Clara würde bei der Aufgabe 29 + 6 also
                            vermutlich zu dem Ergebnis 215 kommen, indem sie
                            zuerst 2 + 0 = 2, anschließend 9 + 6 = 15 rechnet
                            und beide Teilergebnisse hintereinander aufschreibt.
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>

              <template v-slot:alternative-content-1>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>
                          <div class="imageTableImages tableImageContainer">
                            <AppExpandableImage
                              :src="
                                require('@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Clara.png')
                              "
                              contain
                              parentId="SWVI"
                              elementId="Einzeldiagnose/Einzeldiagnose_Rechnung_Clara.png"
                            />
                          </div>
                        </td>
                        <td>
                          <p>
                            Clara verfügt dementsprechend bereits über die
                            Kompetenz, Additionsaufgaben von ein- und
                            zweistelligen Zahlen zu zweistelligen Summen
                            stellenweise zu lösen, solange dabei keine Bündelung
                            erforderlich ist.
                          </p>
                          <p>
                            Die Ursache für die Fehler könnte sein, dass Clara
                            das Prinzip des Stellenwerts und das Prinzip der
                            Bündelung noch nicht verstanden hat. Dass Clara das
                            Prinzip des Stellenwerts noch nicht verstanden hat,
                            kann daran vermutet werden, dass sie zwei Ziffern
                            zur Besetzung eines Stellenwerts notiert. Dies
                            deutet auch auf Probleme mit dem Bündelungsprinzip
                            hin, denn Clara schreibt zwei Ziffern an eine
                            Stelle, da sie keine Bündelung durchführt bzw.
                            Elemente der gleichen Bündelungseinheit (Zehner = Z,
                            Einer = E) nicht zusammenfasst. Clara rechnet 9 E +
                            6 E = 15 E. Die 15 Einer werden jedoch nicht als 1
                            Zehner und 5 Einer erkannt bzw. der eine Zehner
                            nicht mit den beiden anderen Zehnern aus der
                            Rechnung 2 Z + 0 Z = 2 Z zusammengefasst.
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>

              <template v-slot:alternative-content>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>
                          <div class="imageTableImages tableImageContainer">
                            <AppExpandableImage
                              :src="
                                require('@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Clara.png')
                              "
                              contain
                              parentId="SWVI"
                              elementId="Einzeldiagnose/Einzeldiagnose_Rechnung_Clara.png"
                            />
                          </div>
                        </td>
                        <td>
                          <p>
                            In dieser Abbildung siehst Du, wie Clara drei
                            Aufgabe gelöst hat. Anhand der vierten Aufgabe 29 +
                            6 wird rekonstruiert, wie Clara beim Lösen der
                            Aufgaben vermutlich vorgegangen ist, über welche
                            Kompetenzen sie bereits verfügt und was wir über die
                            Ursache des Fehlers vermuten können.
                          </p>
                          <p>
                            Betrachtet man die Lösungen der oberen drei
                            Aufgaben, so fällt auf, dass Clara vermutlich
                            jeweils die beiden Summanden stellenweise, dabei
                            eventuell auch die Zehner und Einer ziffernweise,
                            betrachtet und separat addiert. Die dabei
                            herauskommenden Summen notiert sie hintereinander,
                            anstatt sie wiederum stellengerecht miteinander zu
                            addieren. Clara würde bei der Aufgabe 29 + 6 also
                            vermutlich zu dem Ergebnis 215 kommen, indem sie
                            zuerst 2 + 0 = 2, anschließend 9 + 6 = 15 rechnet
                            und beide Teilergebnisse hintereinander aufschreibt.
                          </p>
                          <p>
                            Clara verfügt dementsprechend bereits über die
                            Kompetenz, Additionsaufgaben von ein- und
                            zweistelligen Zahlen zu zweistelligen Summen
                            stellenweise zu lösen, solange dabei keine Bündelung
                            erforderlich ist.
                          </p>
                          <p>
                            Die Ursache für die Fehler könnte sein, dass Clara
                            das Prinzip des Stellenwerts und das Prinzip der
                            Bündelung noch nicht verstanden hat. Dass Clara das
                            Prinzip des Stellenwerts noch nicht verstanden hat,
                            kann daran vermutet werden, dass sie zwei Ziffern
                            zur Besetzung eines Stellenwerts notiert. Dies
                            deutet auch auf Probleme mit dem Bündelungsprinzip
                            hin, denn Clara schreibt zwei Ziffern an eine
                            Stelle, da sie keine Bündelung durchführt bzw.
                            Elemente der gleichen Bündelungseinheit (Zehner = Z,
                            Einer = E) nicht zusammenfasst. Clara rechnet 9 E +
                            6 E = 15 E. Die 15 Einer werden jedoch nicht als 1
                            Zehner und 5 Einer erkannt bzw. der eine Zehner
                            nicht mit den beiden anderen Zehnern aus der
                            Rechnung 2 Z + 0 Z = 2 Z zusammengefasst.
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
            </LernbausteinStempeln>
          </div>
        </AppButtonCollapse>
      </AppButtonCollapse>

      <p>
        Die Ursachen von Fehlern können sehr vielfältig sein. Das Erkennen
        dieser Ursachen bildet die Grundlage für eine individuelle und adaptive
        Förderung, durch welche individuelle Fehlvorstellungen aufgearbeitet und
        durch ein besseres Verständnis abgebaut werden können.
      </p>

      <p>
        Das passgenaue Zusammenspiel von Diagnostik und gezielter Förderung ist
        zentral für das gesamte unterrichtliche Geschehen. Im nachfolgenden
        Beispiel haben Sie nun die Gelegenheit zunächst eine Feindiagnostik auf
        Grundlage erster diagnostischer Erkenntnisse durchzuführen und im
        Anschluss daran eine geeignete Förderaufgabe auszuwählen.
      </p>
      <div style="background-color: white">
        <LernbausteinPfade
          :id="LBSTPfadeBeispiele[0].id"
          :workOrder="LBSTPfadeBeispiele[0].workOrder"
          :image="LBSTPfadeBeispiele[0].image"
          :imageSizePercent="LBSTPfadeBeispiele[0].imageSizePercent"
          :mediaDescription="LBSTPfadeBeispiele[0].mediaDescription"
          :audio="LBSTPfadeBeispiele[0].audio"
          :elements="LBSTPfadeBeispiele[0].elements"
          :responses="LBSTPfadeBeispiele[0].responses"
          :pupilName="LBSTPfadeBeispiele[0].pupilName"
        />
      </div>
      <br />

      <AppLiteraturSWV />
    </v-card>
    <AppBottomNavSWV
      back="/stellenwertverstaendnis/uebergang"
      next="/stellenwertverstaendnis/check"
    />
  </div>
</template>

<script>
import AppLiteraturSWV from "@/common/AppLiteraturSWV";
import AppBottomNavSWV from "@/common/AppBottomNavSWV";
import AppButtonCollapse from "@/common/AppButtonCollapse";
import AppExpandableImage from "@/common/AppExpandableImage";
import AppHelper from "@/common/AppHelper";

import LernbausteinGruppen from "@/components/faledia/explorativeElemente/LernbausteinGruppen";
import LernbausteinGruppen_Gruppendiagnose from "@/components/faledia/seiten/swve/beispiele/Gruppendiagnose";

import LernbausteinStempeln from "@/components/faledia/explorativeElemente/LernbausteinStempeln";
import Einzeldiagnose_Marie from "@/components/faledia/seiten/swve/beispiele/Einzeldiagnose_Marie";
import Einzeldiagnose_Taifun from "@/components/faledia/seiten/swve/beispiele/Einzeldiagnose_Taifun";
import Einzeldiagnose_Clara from "@/components/faledia/seiten/swve/beispiele/Einzeldiagnose_Clara";

import LernbausteinPfade from "@/components/faledia/explorativeElemente/LernbausteinPfade";
import LernbausteinPfade_Pfade_Tabea from "@/components/faledia/seiten/stellenwertverstaendnis/beispiele/PfadeTabea";

export default {
  components: {
    AppLiteraturSWV,
    AppBottomNavSWV,
    AppButtonCollapse,
    LernbausteinGruppen,
    LernbausteinStempeln,
    AppExpandableImage,
    VuetifyAudio: () => import("vuetify-audio"),
    LernbausteinPfade,
  },
  data: () => ({
    LBSTGruppen: [LernbausteinGruppen_Gruppendiagnose],
    LBSTStempeln: [
      Einzeldiagnose_Marie,
      Einzeldiagnose_Taifun,
      Einzeldiagnose_Clara,
    ],
    LBSTPfadeBeispiele: [LernbausteinPfade_Pfade_Tabea],
  }),
  methods: {
    audioEnded(id) {
      AppHelper.audioEnded(this, id);
    },

    audioPlayerClicked(event, id, ref) {
      AppHelper.audioPlayerClicked(this, event, id, ref);
    },
  },
};
</script>

<style scoped>
.imageTableImages {
  margin-top: 10px;
  margin-bottom: 10px;
}

.tableImageContainer {
  width: 179px;
}

.biggerTableImageContainer {
  width: 390px;
}
</style>
