<template>
  <AppBottomNav :items="items" :back="back" :next="next" />
</template>

<script>
import AppBottomNav from "./AppBottomNav";
export default {
  props: {
    back: String,
    next: String,
  },
  components: {
    AppBottomNav,
  },
  data() {
    return {
      items: [
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Stellenwertverständnis",
          to: "/stellenwertverstaendnis",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Hintergrundwissen",
          to: "/stellenwertverstaendnis/hintergrundwissen",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Bündeln und Entbündeln",
          to:
            "/stellenwertverstaendnis/hintergrundwissen/buendeln-und-entbuendeln",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Stellenwert und Zahlenwert",
          to:
            "/stellenwertverstaendnis/hintergrundwissen/stellenwert-und-zahlenwert",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Darstellungswechsel",
          to: "/stellenwertverstaendnis/hintergrundwissen/darstellungswechsel",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Übergang Diagnose",
          to: "/stellenwertverstaendnis/uebergang",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Diagnose & Förderung",
          to: "/stellenwertverstaendnis/diagnose-und-foerderung",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Kompetenzliste",
          to: "/stellenwertverstaendnis/check",
        },
      ],
    };
  },
};
</script>

<style></style>
