<style scoped>
div {
  background: rgba(0, 0, 0, 0) !important;
}
</style>
<template>
  <AppButtonCollapse
    icon="mdi-book-open-page-variant-outline"
    messageHidden="Literatur anzeigen"
    messageNotHidden="Literatur verstecken"
    elevation="0"
  >
    <div>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>
                Carpenter, T., Franke, M., Jacobs, V., Fennema, E., & Empson, S.
                (1998).
                <i
                  >A Longitudinal Study of Invention and Understanding in
                  Children's Multidigit Addition and Subtraction.</i
                >
                In Journal for Research in Mathematics Education, Vol 29 Issue 1
                (S. 3-20).
              </td>
            </tr>
            <tr>
              <td>
                Fromme, M. (2017).
                <i
                  >Stellenwertverständnis im Zahlenraum bis 100: Theoretische
                  und empirische Analysen.</i
                >
                Wiesbaden: Springer Spektrum.
              </td>
            </tr>
            <tr>
              <td>
                Krauthausen, G., & Scherer, P. (2007).
                <i>Einführung in die Mathematikdidaktik.</i> München: Elsevier.
              </td>
            </tr>
            <tr>
              <td>
                Kuhnke, K. (2012).
                <i
                  >Vorgehensweisen von Grundschulkindern beim
                  Darstellungswechsel – Eine Untersuchung am Beispiel der
                  Multiplikation im 2. Schuljahr.</i
                >
                Wiesbaden: Springer.
              </td>
            </tr>
            <tr>
              <td>
                Möller, K., Pixner, S., Zuber, J., Kaufmann, L., & Nürk, H.-C.
                (2011).
                <i
                  >Early place-value understanding as a precursor for later
                  arithmetic performance—A longitudinal study on numerical
                  development.</i
                >
                In Research in Developmental Disabilities, Vol 32 Issue 5 (S.
                1837-1851).
              </td>
            </tr>
            <tr>
              <td>
                Moser Opitz, E. (2007).
                <i
                  >Rechenschwäche – Dyskalkulie. Theoretische Klärungen und
                  empirische Studien an betroffenen Schülerinnen und
                  Schülern.</i
                >
                Bern: Haupt.
              </td>
            </tr>
            <tr>
              <td>
                Moser Opitz, E., & Schmassmann M. (2007).
                <i>Grundoperationen.</i> In Heimlich, U., Wember, F. (Hrsg.),
                <i
                  >Didaktik des Unterrichts im Förderschwerpunkt Lernen. Ein
                  Handbuch für Studium und Praxis</i
                >
                (S. 266-279). Stuttgart: Kohlhammer.
              </td>
            </tr>
            <tr>
              <td>
                Padberg, F., & Benz, C. (2011).
                <i
                  >Didaktik der Arithmetik. Für Lehrerausbildung und
                  Lehrerfortbildung.</i
                >
                4. Aufl. Heidelberg: Spektrum.
              </td>
            </tr>
            <tr>
              <td>
                Schipper, W. (2009).
                <i>Handbuch für den Mathematikunterricht an Grundschulen.</i>
                Hannover: Schroedel.
              </td>
            </tr>
            <tr>
              <td>
                Schulz, A. (2015).
                <i
                  >Von der Handlung in den Kopf - Übungsformen zur
                  Verinnerlichung von Handlungen.</i
                >
                Fördermagazin Grundschule, 4, (S. 15-21).
              </td>
            </tr>
            <tr>
              <td>
                Thompson, I., & Bramald, R. (2002).
                <i
                  >An investigation of the relationship between young children’s
                  understanding of the concept of place value and their
                  competence at mental addition.</i
                >
                (Report for the Nuffield Foundation). University of Newcastle
                upon Tyne.
              </td>
            </tr>
            <tr>
              <td>
                Wartha, S., & Schulz, A. (2014).
                <i>Rechenproblemen vorbeugen.</i> Berlin: Cornelsen.
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </AppButtonCollapse>
</template>

<script>
import AppButtonCollapse from "@/common/AppButtonCollapse";

export default {
  name: "AppLiteraturSWV",
  components: {
    AppButtonCollapse,
  },
};
</script>
